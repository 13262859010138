import React, { useState } from "react";
import {
  User,
  UserManager,
  Log,
  UserManagerSettings,
  WebStorageStateStore,
} from "oidc-client";
import { useUserManager } from "../Authentication/UserManagerHook";
import { createCtx } from "./ContextUtils";
import { Modal } from "../Components/Modal/Modal";

export type ModalOptions = {
  isOpen: boolean;
  content: React.ReactNode;
  title?: string;
};

export type GlobalState = {
  userManager: UserManager;
  user: User | undefined;
  signInRequired: boolean;
  signInInProgress: boolean;
  setSignInInProgress: (value: boolean) => void;
  signInError: boolean;
  setSignInError: (value: boolean) => void;
  showModal: (options: ModalOptions) => void;
  hideModal: () => void;
};
export const [useGlobalContext, SettingProvider] = createCtx<GlobalState>(); // specify type, but no need to specify value upfront!

export type GlobalStateProviderProps = {
  children: React.ReactNode;
};

const emptyModal: ModalOptions = {
  content: <></>,
  isOpen: false,
};

const config: UserManagerSettings = {
  authority: process.env.REACT_APP_DATA_AUTH_AUTHORITY,
  client_id: "ExecuteCRISP",
  redirect_uri: process.env.REACT_APP_DATA_AUTH_REDIRECT_URI,
  response_type: "code",
  scope: "openid profile email",
  post_logout_redirect_uri: process.env.REACT_APP_DATA_AUTH_LOGOUT_REDIRECT_URL,
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_DATA_AUTH_SILENT_REDIRECT_URL,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const userManager = new UserManager(config);
userManager.clearStaleState();

Log.level = process.env.NODE_ENV === "development" ? Log.DEBUG : Log.NONE;
Log.logger = console;
const GlobalStateProvider = ({ children }: GlobalStateProviderProps) => {
  const [signInInProgress, setSignInInProgress] = useState(false);
  const [signInError, setSignInError] = useState(false);
  const [modalOptions, setModalOptions] = useState<ModalOptions>(emptyModal);
  const [user, signInRequired, silentRenewError] = useUserManager({
    userManager,
  });

  if (silentRenewError) {
    userManager.clearStaleState();
    userManager.removeUser();
    return <></>; //
  } else {
    return (
      <SettingProvider
        value={{
          userManager: userManager,
          user: user,
          signInRequired: signInRequired,
          signInInProgress: signInInProgress,
          setSignInInProgress: (value: boolean) => setSignInInProgress(value),
          setSignInError: (value: boolean) => setSignInError(value),
          signInError: signInError,
          showModal: (value: ModalOptions) => setModalOptions(value),
          hideModal: () => setModalOptions(emptyModal),
        }}
      >
        {children}
        <Modal options={modalOptions}></Modal>
      </SettingProvider>
    );
  }
};

export { GlobalStateProvider };
