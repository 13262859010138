import React from "react";
import { GlobalStateProvider } from "./Contexts/GlobalState";
import { AppRoutes } from "./AppRoutes";
import styles from "./App.module.scss";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import {
  AppInsightsContext,
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();

var reactPlugin = new ReactPlugin();

const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    customDataPrefix: "data-custom-",
    aiBlobAttributeTag: "customblob",
    parentDataTag: "parent-group",
    donotTrackDataTag: "dnt",
    captureAllMetaDataContent: true,
    useDefaultContentNameOrId: true,
  },
  //behaviorValidator: BehaviorMapValidator(behaviorMap),
  defaultRightClickBhvr: "rc",
};
var appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
  },
});
appInsights.loadAppInsights();

export const setAuthenticatedUserContext = (id: string | undefined) => {
  if (id) {
    appInsights.setAuthenticatedUserContext(id, undefined, true);
  } else {
    appInsights.clearAuthenticatedUserContext();
  }
};

class App extends React.Component {
  render(): React.ReactNode {
    return (
      <AppInsightsContext.Provider value={reactPlugin}>
        <div className={styles.app}>
          <GlobalStateProvider>
            <AppRoutes />
          </GlobalStateProvider>
        </div>
      </AppInsightsContext.Provider>
    );
  }
}

export default withAITracking(reactPlugin, App);
