import React from "react";
import styles from "./DataNotice.module.scss";
import { Trans } from "react-i18next";

const DataNotice = () => {
  return (
    <div data-testid="data-notice" className={`${styles.dataNotice}`}>
      <p>
      <Trans i18nKey="gdpr:dataNotice">
        Welcome to the China 1 DEEP Portal. Your email address and name have
        been provided for the purpose of creating a user account in order to
        access the asset55 and JGS software used on this project. The legal
        basis for processing your personal data in this context is Regulation
        (EU) 2016/679 (General Data Protection Regulation = GDPR). The
        processing is necessary for the performance of the contract for
        accessing and using the DEEP Portal. For further information, please see
        the relevant privacy policy of each system. Please email 
        <a href="mailto:helpdesk@assetfiftyfive.com">helpdesk@assetfiftyfive.com</a> for support with the China 1 DEEP Portal.
			</Trans>
      </p>
    </div>
  );
};

export { DataNotice };
